// import React, { Component } from 'react';
// import {  NavLink ,redirect ,useNavigate     } from 'react-router-dom';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import { login, logout, isLoggedIn, getToken,SaveData } from './Auth'; 
// import variables from '../Variables';
// import Button from 'react-bootstrap/Button';
// import Row from 'react-bootstrap/Row';
// import { ToastContainer, toast } from 'react-toastify';


// class Login extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {

//             email:"",
//             password:"",
//             error:"",
           
//             validated: false, setValidated: false,
//             initialValues: { username: '', email: '', password: '' },
//             formValues: { username: '', email: '', password: '' },
//             formErrors: {},
           

//         }
//     }
//     handleChangeEmail = (e) => {
//         this.setState({
//             email: e.target.value,
//             validated:true,
            
//         });
//     };
//     handleSubmit=(e)=>{
//         e.preventDefault();
//         const form = e.currentTarget;
//         if (form.checkValidity() === true) {
//             const st = {

//                 Email: this.state.email,
//                 Password:this.state.password
//             };
//             fetch(variables.API_URL + 'accounts/login', {
//                 method: 'POST',
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify(st)
//             })
    
//                 .then(res => res.json())
//                 .then((result) => {
//                     debugger;
//                     if(result.status){
//                         login(result.result.getToken);
//                         SaveData("Name",result.result.name);
//                         SaveData("email",result.result.email);
//                         SaveData("role",result.result.role);
                        
//                         toast.success("User Login Successfuly")
//                        const  navigate = useNavigate();
                      
//                           navigate('/');
                        

//                     }
//                     else{
//                         result.errors.map(x=>{
//                             toast.error(x);

//                         });
//                     }
    
    
    
//                 }, (error) => {
//                     alert('Failed');
//                 })


//         }
//         else{
//             this.setState({
               
//                 validated:true,
    
//             }); 
//         }





//     }
//     handleChangePassword = (e) => {
//         this.setState({
//             password: e.target.value,
//             validated:true,

//         });
//     };

//     render() 
    
//     {
        
//         const {
//             validated,
//             setValidated,
//             email,
//             password}=this.state;

//         return (
//             <div>
//                 <ToastContainer/>
//                 <div className="main-wrapper login-body">
// 	<div className="container">
// 		<header className="log-header">
//             <NavLink to="/"><img className="img-fluid logo-dark" src="assets/img/logo.svg" alt="Logo"/></NavLink>
			
// 		</header>

// 		<div className="login-wrapper">
// 			<div className="loginbox">						
// 				<div className="login-auth">
// 					<div className="login-auth-wrap">
// 						<h1>Hey There!!! Welcome Back.</h1>							
// 					   <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
//                         <Row className="mb-3">
//                                         <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
//                                             <Form.Label>Email</Form.Label>
//                                             <Form.Control
//                                                 required
//                                                 type="email"
//                                                 placeholder="Enter Email"

//                                                 value={email}
//                                                 onChange={this.handleChangeEmail}

//                                                 aria-describedby="fname"
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Email
//                                             </Form.Control.Feedback>
//                                             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//                                         </Form.Group>
//                                         <Form.Group as={Col} md="12" id="password" controlId="validationCustom01">
//                                             <Form.Label>Password</Form.Label>
//                                             <Form.Control
//                                                 required
//                                                 type="password"
//                                                 placeholder="*******"

//                                                 value={password}
//                                                 onChange={this.handleChangePassword}

//                                                 aria-describedby="password"
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Password
//                                             </Form.Control.Feedback>
//                                             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//                                         </Form.Group>
//                        </Row>
//                        <Button type="submit" className="btn btn-outline-light w-100 btn-size">Sign In</Button>

//                         	<div className="login-or">
// 								<span className="span-or-log">Or, Sign in with your email</span>
// 							</div>
						
							
// 							<div className="text-center dont-have">Don't have an account ? <a href="register.html">Sign Up</a></div>
//                     </Form>
                        							
// 					</div>
// 				</div>
// 			</div>
// 		</div>	
// 	</div>		
// </div>
		
//             </div>
//         );
//     }
// }

// export default Login;

import React, { useState ,useEffect} from 'react';
import { NavLink ,useNavigate} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { login, SaveData ,isLoggedIn} from './Auth';
import variables from '../Variables';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setValidated(true);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setValidated(true);
  };
  const navigate = useNavigate();
  useEffect(() => {
    // Check for the existence of the token on component load
    if (isLoggedIn()) {
      // If the token exists, navigate to the desired page
      navigate('/');
    }
  }, [navigate]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity()) {
      const st = {
        Email: email,
        Password: password
      };

      fetch(variables.API_URL + 'accounts/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(st)
      })
        .then(res => res.json())
        .then((result) => {
          if (result.status) {
            toast.success("User Login Successfully");
            
            login(result.result.token);
            SaveData("Name", result.result.name);
            SaveData("email", result.result.email);
            SaveData("role", result.result.role);
            SaveData("phone", result.result.phoneNumber);
            SaveData("fullName", result.result.fullName);
            
            navigate("/");

          } else {
            result.errors.forEach(x => {
              toast.error(x);
            });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error('Failed to log in. Please try again.');
        });
    } else {
      setValidated(true);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="main-wrapper login-body">
        <div className="container">
          <header className="log-header">
          <NavLink to="/"><img className="img-fluid logo-dark"  src="assets/lg.png" alt="Logo" /></NavLink>
       
            {/* <NavLink to="/"><img className="img-fluid logo-dark" src="assets/img/logo.svg" alt="Logo" /></NavLink> */}
          </header>

          <div className="login-wrapper">
            <div className="loginbox">
              <div className="login-auth">
                <div className="login-auth-wrap">
                  <h1>Hey There!!! Welcome Back.</h1>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={handleChangeEmail}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Email
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="*******"
                          value={password}
                          onChange={handleChangePassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Password
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Button type="submit" className="btn btn-outline-light w-100 btn-size">Sign In</Button>

                    <div className="login-or">
                      <span className="span-or-log">Or, Sign in with your email</span>
                    </div>

                    <div className="text-center dont-have">Don't have an account? <NavLink to="/signup">Sign Up</NavLink></div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;