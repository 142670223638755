import React, { Component } from 'react';
import variables from '../Variables';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Dialog } from 'primereact/dialog';
import { isLoggedIn,GetData,getToken, isAdmin } from '../Accounts/Auth';
import { useNavigate } from 'react-router-dom';

export class UserPage extends Component {

    constructor(props) {
        super(props);

        this.state = {

            Users: [],
            show: false,
            modalTitle: "Add User",
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            role: "Admin",
            approved: true,
            phoneNo: "",
            rowVersion: "",
            validated: false, setValidated: false,
            initialValues: { username: '', email: '', password: '' },
            formValues: { username: '', email: '', password: '' },
            formErrors: {},
            username: "",
            isSubmit: false,
            country: "Norway",
            address: "",
            error: "",
            filters: [],
            loading: false,
            globalFilterValue: "",
            

        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formValues: { ...prevState.formValues, [name]: value },
        }));
    };
    showToastMessage = () => {

        toast.success("Success Notification !", {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ formErrors: this.validate(this.state.formValues), isSubmit: true });
    };

    validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.username) {
            errors.username = 'Username is required!';
        }
        if (!values.email) {
            errors.email = 'Email is required!';
        } else if (!regex.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if (!values.password) {
            errors.password = 'Password is required';
        } else if (values.password.length < 4) {
            errors.password = 'Password must be more than 4 characters';
        } else if (values.password.length > 10) {
            errors.password = 'Password cannot exceed more than 10 characters';
        }
        return errors;
    };
// get all  the  user

    refreshList() {
        if(isAdmin()){
            fetch(variables.API_URL + 'user', {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
    
                }})
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.setState({ Users: data });
                });
        }
      

    }
    handleClose = () => this.setState({
        modalTitle: "Add User",
        show: false,
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: "Admin",
        approved: true,
        phoneNo: "",
        rowVersion: "",
        address: "",
        country: "Norway",
        username: ""


    });
    handleShow = () => this.setState({ show: true });
    

    componentDidMount() {
        // const navigate = useNavigate();
        if(!isLoggedIn()){
            
           // window.location.href = '/login'
            // navigate('/login');
        }
        else{
            if(GetData("role")!=="Admin"){

            }
        }
        
        this.refreshList();
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.formErrors !== this.state.formErrors) {
            console.log(this.state.formErrors);
        }

        if (Object.keys(this.state.formErrors).length === 0 && this.state.isSubmit) {
            console.log(this.state.formValues);
        }
    }
    handleSubmit = (event) => {
        debugger;
        event.preventDefault();
        const form = event.currentTarget;
        if (this.state.password !== this.state.confirmPassword) {
            toast.error("Password and Confirm Password Does not Match");
            this.setState({ error: "Password and Confirm Password Does not Match" });



        }
        else {
            this.setState({ error: "" });
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            else if (this.state.id === "") {
                this.createClick();

            }
            else {

                this.updateClick();
            }

            this.setState({ validated: true });

        }

    };



    // changeEmployeeName = (e) => {
    //     this.setState({ EmployeeName: e.target.value });
    // }
    // changeDepartment = (e) => {
    //     this.setState({ Department: e.target.value });
    // }
    // changeDateOfJoining = (e) => {
    //     this.setState({ DateOfJoining: e.target.value });
    // }

    // addClick() {
    //     this.setState({
    //         modalTitle: "Add Employee",
    //         EmployeeId: 0,
    //         EmployeeName: "",
    //         Department: "",
    //         DateOfJoining: "",
    //         PhotoFileName: "anonymous.png"
    //     });
    // }

    // Edit the  user

    editClick(emp) {
        debugger;

        this.setState({
            modalTitle: "Edit User",
            id: emp.id,
            firstName: emp.firstName,
            lastName: emp.lastName,
            username: emp.userName,
            phoneNo: emp.phoneNumber,
            email: emp.email,
            role: emp.role,
            approved: emp.emailConfirmed,
            country: emp.country,
            show: true,
            address: emp.address

        });
    }
    // update status of the  user

    updateStatus(emp) {
        const st = {

            id: emp.id
        };
        fetch(variables.API_URL + 'user/updateStatus', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(st)
        })

            .then(res => res.json())
            .then((result) => {
                if (result.includes('Inactive')) {
                    toast.warning(result);

                }
                else {
                    toast.success(result);

                }
                this.refreshList();



            }, (error) => {
                alert('Failed');
            })
    }
// create the  user

    createClick() {
        const st = {

            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email,
            Role: this.state.role,
            Approved: this.state.approved,
            PhoneNo: this.state.phoneNo,
            Id: "3456",
            RowVersion: this.state.RowVersion,
            UserName: this.state.username,
            Address: this.state.address,

            Country: this.state.country,
            Password: this.state.password,
            ConfirmPassword: this.state.confirmPassword
        
        };
        console.log(st);
        fetch(variables.API_URL + 'user/AddUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(st)
        })

            .then(res => res.json())
            .then((result) => {
                if (!result.succeeded) {
                    result.errors.map(x => {
                        toast.error(x.description);

                    });

                }
                else {
                    toast.success("User Has been Added");

                    this.setState({ show: false });

                    this.refreshList();
                }

            }, (error) => {
                toast.error(error);

            })
    }

// update the  user

    updateClick() {
        const jsonBody = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email,
            Role: this.state.role,
            Approved: this.state.approved,
            PhoneNo: this.state.phoneNo,
            Id: this.state.id,
            UserName: this.state.username,
            Country: this.state.country,
            Address: this.state.address,

        };
        fetch(variables.API_URL + 'user/UpdateUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(jsonBody)
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                if (!result.succeeded) {

                    result.errors.map(x => {
                        toast.error(x.description);

                    });

                }
                else {
                    toast.success("User Has been Updated");

                    this.setState({ show: false });

                    this.refreshList();
                }
            }, (error) => {

                toast.error(error);

            })
    }
// delete the  user

    deleteClick(id) {
        if (window.confirm('Are you sure?')) {
            fetch(variables.API_URL + 'user/' + id, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then((result) => {
                    if (result.succeeded) {
                        toast.info("User Has been Deleted");
                        this.refreshList();

                    }


                }, (error) => {

                    toast.error(error);

                })
        }
    }

    imageUpload = (e) => {
        e.preventDefault();
        debugger;
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);

        fetch(variables.API_URL + 'employee/savefile', {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                this.setState({ PhotoFileName: data });
            }, (error) => {
                alert(error);
            })
    }
   
    onGlobalFilterChange = (e) => {
        this.setState({ globalFilterValue: e.target.value });
    };
    handleChangeFname = (e) => {
        this.setState({
            firstName: e.target.value,
        });
    };
    handleChangeLname = (e) => {
        this.setState({
            lastName: e.target.value,
        });
    };
    handleChangeEmailname = (e) => {
        this.setState({
            email: e.target.value,
        });
    };
    handleChangeUsername = (e) => {
        this.setState({
            username: e.target.value,
        });
    };
    handleChangePhone = (e) => {
        this.setState({
            phoneNo: e.target.value,
        });
    };
    handleChangeRole = (e) => {
        this.setState({
            role: e.target.value,
        });
    };
    handleChangeAddress = (e) => {
        this.setState({
            address: e.target.value,
        });
    };
    handleChangeCountry = (e) => {
        this.setState({
            country: e.target.value,
        });
    };
    handleChangeApprove = (e) => {
        this.setState((prevState) => ({
            approved: !prevState.approved,
        }));


    };
    handlePassword = (e) => {
        this.setState({
            password: e.target.value,
        });




    };

    handleConfirmPassword = (e) => {
        debugger;
        this.setState({
            confirmPassword: e.target.value,
        });
        if (this.state.password !== e.target.value) {
            this.setState({ error: "Password and Confirm Password Does not Match" });



        }
        else {
            this.setState({ error: "" });
        }




    };
    actionTemplate = (rowData, column) => {
        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <button type="button"
                            className="btn btn-light mr-1"
                            onClick={() => this.editClick(rowData)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button"
                            className="btn btn-light mr-1"
                            onClick={() => this.deleteClick(rowData.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                            </svg>
                        </button>
                    </div>

                </div>




            </div>
        );
    };
    statusCheckbox = (rowData, column) => {
        return (
            <div>


                <Form.Check
                    checked={rowData.emailConfirmed}
                    onClick={() => this.updateStatus(rowData)}

                    feedbackType="invalid"
                />



            </div>
        );
    };
    renderHeader = () => {
        return (
            <div className="row">
                <div className='col-md-6'>
                    <span className="">
                        <i className="pi pi-search" />
                        <input value={this.globalFilterValue} className='form-control' onChange={this.onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                </div>
                <div className='col-md-6 text-end'>
                    <Button variant="primary" onClick={this.handleShow}>
                        Add New
                    </Button>

                </div>
            </div>
        );
    };
    
    render() {
        const {
            validated,
            setValidated,
            show,
            Users,
            modalTitle,
            rowVersion,
            id,
            error,
            country,
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            role,
            approved,
            phoneNo,
            username,
            address,
            formValues, formErrors, isSubmit, filters, loading,globalFilterValue 
        } = this.state;
        const filteredData = Users.filter(item =>
            Object.values(item).some(value =>
              String(value).toLowerCase().includes(globalFilterValue.toLowerCase())
            )
          );
        return (
            <div>
                <ToastContainer />
                <div className='card'>
                    <div className='card-header'>
                        <h3>Users</h3>
                    </div>
                    <div className='card-body'>
                        <div className="container">



                        </div>

                        <Modal show={this.state.show} onHide={this.handleClose} size="lg" backdrop="static"
                            keyboard={false} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{modalTitle}</Modal.Title>
                            </Modal.Header>

                            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                                <Modal.Body>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" id="fname" controlId="validationCustom01">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="First name"

                                                value={firstName}
                                                onChange={this.handleChangeFname}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Last Name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="lname" controlId="validationCustom02">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Last name"

                                                aria-describedby="lname"
                                                value={lastName}
                                                onChange={this.handleChangeLname}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Last name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="email" controlId="validationCustom02">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={this.handleChangeEmailname}

                                                aria-describedby="email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter the Email.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                            <Form.Label>Username</Form.Label>
                                            <InputGroup hasValidation>

                                                <Form.Control
                                                    type="text"
                                                    placeholder="Username"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    value={username}
                                                    onChange={this.handleChangeUsername}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a username.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="Phone" controlId="validationCustom02">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Phone Number"

                                                aria-describedby="Phone"
                                                value={phoneNo}
                                                onChange={this.handleChangePhone}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Phone Number
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" id="Role" controlId="validationCustom02">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select aria-label="Default select example" required aria-describedby="Role"
                                                value={role}
                                                onChange={this.handleChangeRole}

                                            >
                                                <option value="Admin">Admin</option>
                                                <option value="Customer">Customer</option>

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="address" controlId="validationCustom02">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" aria-label="Default select example" aria-describedby="address"
                                                value={address}
                                                onChange={this.handleChangeAddress}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" id="counrty" controlId="validationCustom02"

                                        >
                                            <Form.Label>Counrty</Form.Label>
                                            <Form.Select aria-label="Default select example" required aria-describedby="Role"
                                                value={country}
                                                onChange={this.handleChangeCountry}

                                            >
                                                <option value="Norway">Norway</option>


                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        {id === '' ?

                                            <Form.Group as={Col} md="4" id="Password" controlId="validationCustom02">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={this.handlePassword}

                                                    aria-describedby="Password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>


                                            : null


                                        }
                                        {id === '' ?


                                            <Form.Group as={Col} md="4" className="mt-1" id="ConfirmPassword" controlId="validationCustom02">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Confirm Password"

                                                    aria-describedby="ConfirmPassword"
                                                    value={confirmPassword}
                                                    onChange={this.handleConfirmPassword}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Confirm Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <p className='text-danger'>{error}</p>

                                            </Form.Group>

                                            : null


                                        }
                                        <Form.Group as={Col} md="12" className="mb-3">

                                            <Form.Check
                                                checked={approved}
                                                onChange={this.handleChangeApprove}
                                                label="Is Active"
                                                feedback="You must agree before submitting."
                                                feedbackType="invalid"
                                            />

                                        </Form.Group>




                                    </Row>

                                </Modal.Body>

                                <Modal.Footer>

                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button type="submit">Save</Button>



                                </Modal.Footer>
                            </Form>

                        </Modal>

                        <DataTable value={filteredData} showGridlines paginator header={this.renderHeader} rows={10} size='small' fil rowsPerPageOptions={[5, 10, 25, 50]} stripedRows tableStyle={{ minWidth: '50rem' }} >
                            <Column field="role" header="Role" sortable filter filterPlaceholder="Search by Role" ></Column>
                            <Column field="firstName" header="First Name" sortable ></Column>
                            <Column field="lastName" header="Last Name" sortable ></Column>
                            <Column field="email" header="Email" sortable ></Column>

                            <Column field="phoneNumber" header="Phone Number" sortable ></Column>
                            <Column field="emailConfirmed" header="status" body={this.statusCheckbox} sortable ></Column>
                            <Column body={this.actionTemplate} style={{ textAlign: 'center' }} header="Action" />
                        </DataTable>


                    </div>
                </div>



            </div>
        )
    }
}