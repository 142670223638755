import logo from './logo.svg';
import './App.css';

import  Home  from './Home';

import { BrowserRouter as Router, Route, Switch, NavLink, Routes, Outlet } from 'react-router-dom';

import Test from './Test';
import { UserPage } from './Users/UserPage';
import Frontend from './FrontEnd/Frontend';
import Backend from './Backend/Backend';
import Error from './Error';

import './assets/css/bootstrap.min.css';
import './assets/plugins/fontawesome/css/fontawesome.min.css';

import './assets/plugins/fontawesome/css/all.min.css';
import './assets/css/feather.css';
import './assets/plugins/boxicons/css/boxicons.min.css';

import './assets/css/aos.css';
import './assets/plugins/select2/css/select2.min.css';
import './assets/css/style.css';
import { Properties } from './Properties';
import Accounts from './Accounts/Accounts';
import Login from './Accounts/Login';
import Logout from './Accounts/Logout';
import Profile from './Accounts/Profile';

import SignUp from './Accounts/SignUp';
import {EstatePage} from './Estate/EstatePage';
import { Contacts } from './Contacts/Contacts';
import ChangePassword from './Accounts/ChangePassword';

function App() {
  return (
    <Router>
      {/* <div class="page-loader">
    <div class="page-loader-inner">
        <img src="assets/img/icons/loader.svg" alt="Loader" />
        <label><i class="fa-solid fa-circle"></i></label>
        <label><i class="fa-solid fa-circle"></i></label>
        <label><i class="fa-solid fa-circle"></i></label>
    </div>
</div> */}
       <Routes>
          <Route path='/' element={<Frontend/>}>
                        <Route index element={<Home/>}/>
          </Route>
          {/* <Route path='/logout' element={<Frontend/>}>
                        <Route index element={<Logout/>}/>
          </Route> */}
          {/* <Route path='/error' element={<Frontend/>}>
                        <Route index element={<Error/>}/>
          </Route>
           */}
          <Route path='logout' element={<Logout/>}/>
         
          <Route path='error' element={<Error/>}/>
          
          <Route path='/login' element={<Accounts/>}>
                        <Route index element={<Login/>}/>
          </Route>

          <Route path='/signup' element={<Accounts/>}>
                        <Route index element={<SignUp/>}/>
          </Route>
          
          <Route path='/user' element={<Backend/>}>
                   <Route index element={<UserPage/>}></Route>
         

          </Route>
          <Route path='/response' element={<Backend/>}>
                   <Route index element={<Contacts/>}></Route>
         

          </Route>
          <Route path='/profile' element={<Backend/>}>
                   <Route index element={<Profile/>}></Route>
         

          </Route>
          <Route path='/changepassword' element={<Backend/>}>
                   <Route index element={<ChangePassword/>}></Route>
         

          </Route>
          
          <Route path='/estate' element={<Backend/>}>
                   <Route index element={<EstatePage/>}></Route>
         

          </Route>
          
          
          <Route path='*' element={<Error/>}></Route>
        </Routes>
      {/* <div className="App container">
        <h3 className="d-flex justify-content-center m-3">
          My React App
        </h3>

        <nav className="navbar navbar-expand-sm bg-light navbar-dark">
          <ul className="navbar-nav">
            <li className="nav-item m-1">
              <NavLink className="btn btn-light btn-outline-primary" to="/home">
                Home
              </NavLink>
            </li>
            <li className="nav-item m-1">
              <NavLink className="btn btn-light btn-outline-primary" to="/department">
                Department
              </NavLink>
            </li>
            <li className="nav-item m-1">
              <NavLink className="btn btn-light btn-outline-primary" to="/employee">
                Employee
              </NavLink>
            </li>
            <li className="nav-item m-1">
              <NavLink className="btn btn-light btn-outline-primary" to="/user">
                Users
              </NavLink>
            </li>
          </ul>
        </nav>

       
      </div> */}
      
    </Router>
    
  );
}
export default App;