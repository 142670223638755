import React from 'react';
import  { useState ,useEffect} from 'react';
import { NavLink ,useNavigate} from 'react-router-dom';

import InputGroup from 'react-bootstrap/InputGroup';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { login, SaveData ,isLoggedIn,getToken} from './Auth';
import variables from '../Variables';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';


const ChangePassword = () => {

    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [Oldpassword, setOldPassword] = useState("");
    const [Name, setName] = useState("");
   
   
    const [validated, setValidated] = useState(false);
    const [initialValues, setInitialValues] = useState({ username: '', email: '', password: '' });
    const [formValues, setFormValues] = useState({ username: '', email: '', password: '' });
    const [formErrors, setFormErrors] = useState({});
    const [username, setUsername] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [error, setError] = useState("");
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const handleOlPassword = (e) => {
        setOldPassword(e.target.value);
    };

    
    const navigate=useNavigate();

    useEffect(() => {
        if(!isLoggedIn()){
                navigate("/login");
        }
        else{
            //api hit

 // Function to fetch data (replace with your own data fetching logic)
 const fetchData = async () => {
    try {
      fetch(variables.API_URL + 'accounts/GetByName', {
          headers: {
              'Authorization': `Bearer ${getToken()}`

          }})

          .then(response => response.json())
          .then(data => {
               setUsername(data.userName);
               setEmail(data.email);
               setId(data.id);
              
          });

    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call the fetchData function when the component is mounted
  fetchData();

        }
       
        // If you need to perform cleanup, return a function from useEffect
        return () => {
          // Cleanup logic (if needed)
        };
      }, []); // The empty dependency array means this effect will only run once, equivalent to componentDidMount in class components
    
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
            setError("Password and Confirm Password Do Not Match");
        } else {
            setError("");
        }
    };
  
        const handleSubmit = (e) => {
            e.preventDefault();
            const form = e.currentTarget;
            if(password !==confirmPassword){
                toast.error("Passwords Does'nt Match");
            }else if(form.checkValidity()) {

                const jsonBody = {
                    password: password,
                    id:id ,
                
        
                };
                //api hit
            
                fetch(variables.API_URL + 'user/ChangePassword', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
    

                    },
                    body: JSON.stringify(jsonBody)
                })
                    .then(res => res.json())
                    .then((result) => {
                        console.log(result);
                        if (!result.succeeded) {
        
                            result.errors.map(x => {
                                toast.error(x.description);
        
                            });
        
                        }
                        else {
                            toast.success("Password Has been Changed");
        
                        }
                    }, (error) => {
        
                        toast.error(error);
        
                    })
            //   const st = {
            //     Email: email,
            //     Password: password
            //   };
        
            //   fetch(variables.API_URL + 'accounts/login', {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(st)
            //   })
            //     .then(res => res.json())
            //     .then((result) => {
            //       if (result.status) {
            //         toast.success("User Login Successfully");
                    
            //         login(result.result.getToken);
            //         SaveData("Name", result.result.name);
            //         SaveData("email", result.result.email);
            //         SaveData("role", result.result.role);
        
                    
            //         navigate("/");
        
            //       } else {
            //         result.errors.forEach(x => {
            //           toast.error(x);
            //         });
            //       }
            //     })
            //     .catch((error) => {
            //       console.error('Error:', error);
            //       toast.error('Failed to log in. Please try again.');
            //     });

            } else {
              setValidated(true);
            }
          };
        


    return (
        <div>
        <ToastContainer />
        <div className='card'>
                    <div className='card-header'>
                        <h3>Change Password</h3>
                    </div>
                    <div className='card-body'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                       <Row className="mb-3">
                                    
                                 
                                            <Form.Group as={Col} md="12" id="Password" controlId="validationCustom02">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={handlePassword}

                                                    aria-describedby="Password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter New Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" id="Password" controlId="validationCustom02">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPassword}

                                                    aria-describedby="Password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Confirm Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>

                                      
{/* 
                                        {id === '' ?

                                            <Form.Group as={Col} md="6" id="Password" controlId="validationCustom02">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={handlePassword}

                                                    aria-describedby="Password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>


                                            : null


                                        }
                                        {id === '' ?


                                            <Form.Group as={Col} md="6" className="mt-1" id="ConfirmPassword" controlId="validationCustom02">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Confirm Password"

                                                    aria-describedby="ConfirmPassword"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPassword}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Confirm Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <p className='text-danger'>{error}</p>

                                            </Form.Group>

                                            : null


                                        }
                                         */}



                                    </Row>

                      <Button type="submit" className="btn btn-outline-light w-100 btn-size">Save</Button>
  
                    
                    </Form>
                    </div>
        </div>
        
       
      </div>
    );
}

export default ChangePassword;
