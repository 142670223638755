import React from 'react';
import  { useState ,useEffect} from 'react';
import { NavLink ,useNavigate} from 'react-router-dom';

import InputGroup from 'react-bootstrap/InputGroup';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { login, SaveData ,isLoggedIn,getToken} from './Auth';
import variables from '../Variables';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';


const Profile = () => {

    const [id, setId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("Customer");
    const [approved, setApproved] = useState(true);
    const [phoneNo, setPhoneNo] = useState("");
    const [rowVersion, setRowVersion] = useState("");
    const [validated, setValidated] = useState(false);
    const [initialValues, setInitialValues] = useState({ username: '', email: '', password: '' });
    const [formValues, setFormValues] = useState({ username: '', email: '', password: '' });
    const [formErrors, setFormErrors] = useState({});
    const [username, setUsername] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [country, setCountry] = useState("Norway");
    const [address, setAddress] = useState("");
    const [error, setError] = useState("");
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const navigate=useNavigate();

    useEffect(() => {
        if(!isLoggedIn()){
                navigate("/login");
        }
        else{
            const fetchData = async () => {
                try {
                  fetch(variables.API_URL + 'accounts/GetByName', {
                      headers: {
                          'Authorization': `Bearer ${getToken()}`
          
                      }})
          
                      .then(response => response.json())
                      .then(data => {
                          setRole(data.role);
                           setFirstName(data.firstName);
                           setLastName(data.lastName);
                           setUsername(data.userName);
                           setEmail(data.email);
                           setPhoneNo(data.phoneNumber);
                           setId(data.id);
                           setCountry(data.country);
                           setAddress(data.address);
                           setApproved(data.emailConfirmed)
                          
                      });
          
                
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
              };
          
              // Call the fetchData function when the component is mounted
              fetchData();
          
        }
        // Function to fetch data (replace with your own data fetching logic)
       
        // If you need to perform cleanup, return a function from useEffect
        return () => {
          // Cleanup logic (if needed)
        };
      }, []); // The empty dependency array means this effect will only run once, equivalent to componentDidMount in class components
    
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
            setError("Password and Confirm Password Do Not Match");
        } else {
            setError("");
        }
    };
    const handleChangeFname = (e) => {
        setFirstName(e.target.value);
        };
        const  handleChangeLname = (e) => {
            setLastName(e.target.value);
        
        };
        const  handleChangeEmailname = (e) => {
            setEmail(e.target.value);
        
        };
        const handleChangeUsername = (e) => {
            setUsername(e.target.value);
        
        };
        const  handleChangePhone = (e) => {
            setPhoneNo(e.target.value);
        
        };
        const  handleChangeRole = (e) => {
            setRole(e.target.value);
        
        };
        const  handleChangeAddress = (e) => {
            setAddress(e.target.value);
        
        };
        const handleChangeCountry = (e) => {
            setCountry(e.target.value);
        
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            const form = e.currentTarget;
        
            if (form.checkValidity()) {
                const jsonBody = {
                    FirstName: firstName,
                    LastName: lastName,
                    Email: email,
                    Role: role,
                    Approved: approved,
                    PhoneNo: phoneNo,
                    Id:id ,
                
                    UserName: username,
                    Country: country,
                    Address: address,
        
                };
                fetch(variables.API_URL + 'user/UpdateUser', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
    

                    },
                    body: JSON.stringify(jsonBody)
                })
                    .then(res => res.json())
                    .then((result) => {
                        console.log(result);
                        if (!result.succeeded) {
        
                            result.errors.map(x => {
                                toast.error(x.description);
        
                            });
        
                        }
                        else {
                            toast.success("Account Updated");
        
                        }
                    }, (error) => {
        
                        toast.error(error);
        
                    })
            //   const st = {
            //     Email: email,
            //     Password: password
            //   };
        
            //   fetch(variables.API_URL + 'accounts/login', {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(st)
            //   })
            //     .then(res => res.json())
            //     .then((result) => {
            //       if (result.status) {
            //         toast.success("User Login Successfully");
                    
            //         login(result.result.getToken);
            //         SaveData("Name", result.result.name);
            //         SaveData("email", result.result.email);
            //         SaveData("role", result.result.role);
        
                    
            //         navigate("/");
        
            //       } else {
            //         result.errors.forEach(x => {
            //           toast.error(x);
            //         });
            //       }
            //     })
            //     .catch((error) => {
            //       console.error('Error:', error);
            //       toast.error('Failed to log in. Please try again.');
            //     });

            } else {
              setValidated(true);
            }
          };
        


    return (
        <div>
        <ToastContainer />
        <div className='card'>
                    <div className='card-header'>
                        <h3>Profile</h3>
                    </div>
                    <div className='card-body'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                       <Row className="mb-3">
                                        <Form.Group as={Col} md="6" id="fname" controlId="validationCustom01">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="First name"

                                                value={firstName}
                                                onChange={handleChangeFname}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Last Name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" id="lname" controlId="validationCustom02">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Last name"

                                                aria-describedby="lname"
                                                value={lastName}
                                                onChange={handleChangeLname}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Last name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" id="email" controlId="validationCustom02">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email"
                                                readOnly
                                                value={email}

                                                aria-describedby="email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter the Email.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                                            <Form.Label>Username</Form.Label>
                                            <InputGroup hasValidation>

                                                <Form.Control
                                                    type="text"
                                                    readOnly
                                                    placeholder="Username"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    value={username}
                                                 
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a username.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" id="Phone" controlId="validationCustom02">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Phone Number"

                                                aria-describedby="Phone"
                                                value={phoneNo}
                                                onChange={handleChangePhone}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Phone Number
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" id="counrty" controlId="validationCustom02"

>
    <Form.Label>Counrty</Form.Label>
    <Form.Select aria-label="Default select example" className='form-control' required aria-describedby="Role"
        value={country}
        onChange={handleChangeCountry}

    >
        <option value="Norway">Norway</option>


    </Form.Select>

    <Form.Control.Feedback type="invalid">
        Please Enter First name
    </Form.Control.Feedback>
    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
</Form.Group>

                                        {/* <Form.Group as={Col} md="6" id="Role" controlId="validationCustom02">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select aria-label="Default select example" required aria-describedby="Role"
                                                value={role}
                                                onChange={handleChangeRole}

                                            >
                                                <option value="Customer">Customer</option>

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="12" id="address" controlId="validationCustom02">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" aria-label="Default select example" aria-describedby="address"
                                                value={address}
                                                onChange={handleChangeAddress}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                      
{/* 
                                        {id === '' ?

                                            <Form.Group as={Col} md="6" id="Password" controlId="validationCustom02">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={handlePassword}

                                                    aria-describedby="Password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>


                                            : null


                                        }
                                        {id === '' ?


                                            <Form.Group as={Col} md="6" className="mt-1" id="ConfirmPassword" controlId="validationCustom02">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Confirm Password"

                                                    aria-describedby="ConfirmPassword"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPassword}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Confirm Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <p className='text-danger'>{error}</p>

                                            </Form.Group>

                                            : null


                                        }
                                         */}



                                    </Row>

                      <Button type="submit" className="btn btn-outline-light w-100 btn-size">Save</Button>
  
                    
                    </Form>
                    </div>
        </div>
        
       
      </div>
    );
}

export default Profile;
