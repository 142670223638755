

import React, { useState ,useEffect} from 'react';
import { NavLink ,useNavigate} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { login, SaveData ,isLoggedIn,logout} from './Auth';
import variables from '../Variables';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';

const Logout = () => {
  const [email, setEmail] = useState('arslanshorkot@gmail.com');
  const [password, setPassword] = useState('Arsalan16@');
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
     logout();
    //  toast.success("Logout Successfulys");
    // // Check for the existence of the token on component load
    if (!isLoggedIn()) {

      navigate('/');
    }
  }, [navigate]);
 

  return (
    <div>
    <div className="main-wrapper">
<div className="container">

<div className="error-box">
<img src="assets/img/500.png" width={200} className="img-fluid" alt="Unexpected error"/>
<h1>Logout...</h1>
<p className="error-content">Thank You for using</p>
<div className="back-button">					
  <NavLink to="/login"  className="btn">Login Again</NavLink>
</div>
</div>
</div>
</div>

</div>
  );
};

export default Logout;