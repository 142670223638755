import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink,Link, Routes, Outlet, useNavigate ,redirect  } from 'react-router-dom';

const Error = () => {
    return (
        <div>
              <div className="main-wrapper">
<div className="container">
	
	<div className="error-box">
		<img src="assets/img/500.png" width={200} className="img-fluid" alt="Unexpected error"/>
		<h1>You Don't Have Access</h1>
		<p className="error-content">We’re having some issues at the moment. Please Try to contact to Your Admin.</p>
		<div className="back-button">					
			<NavLink to="/"  className="btn">Back to Home</NavLink>
		</div>
	</div>
</div>
  </div>
		
        </div>
    );
}

export default Error;
