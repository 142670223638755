import React, { Component } from 'react';
import variables from '../Variables';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { ToastContainer, toast } from 'react-toastify';
import Image from 'react-bootstrap/Image';
import 'react-toastify/dist/ReactToastify.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Dialog } from 'primereact/dialog';
import { isLoggedIn, GetData, getToken, isAdmin } from '../Accounts/Auth';
import { useNavigate } from 'react-router-dom';

export class EstatePage extends Component {

    constructor(props) {
        super(props);

        this.state = {

            Estates: [],
            show: false,
            modalTitle: "Add Property",
            Title: '',
            Purpose: 'Rent',
            Bed: '',
            Bath: '',
            PropertyType: 'Appartment',
            Country: 'Norway',
            City: 'Ålesund',
            Address: '',
            Area: '',
            Amount: '',
            Id: 0,
            Picture: '',
            FromDate:'',
            ToDate:'',

            validated: false, setValidated: false,
            formErrors: {},
            isSubmit: false,
            error: "",
            filters: [],
            loading: false,
            globalFilterValue: "",


        }
    }


// getting the data 

    refreshList() {

        fetch(variables.API_URL + 'estates/getestates', {
            headers: {
                'Authorization': `Bearer ${getToken()}`

            }})

            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({ Estates: data });
            });

    }
    handleClose = () => this.setState({
        modalTitle: "Add User",
        show: false,
        Title: '',
        Purpose: 'Rent',
        Bed: '',
        Bath: '',
        PropertyType: 'Appartment',
        Country: 'Norway',
        City: 'Ålesund',
        Address: '',
        Area: '',
        Amount: '',
        Id: 0,
        Picture: '',
        FromDate:'',
            ToDate:'',
            


    });
    handleShow = () => this.setState({ show: true });


    componentDidMount() {
        // const navigate = useNavigate();


        this.refreshList();
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.formErrors !== this.state.formErrors) {
            console.log(this.state.formErrors);
        }

        if (Object.keys(this.state.formErrors).length === 0 && this.state.isSubmit) {
            console.log(this.state.formValues);
        }
    }
    handleSubmit = (event) => {
        debugger;
        event.preventDefault();
        const form = event.currentTarget;
        if (this.state.password !== this.state.confirmPassword) {
            toast.error("Password and Confirm Password Does not Match");
            this.setState({ error: "Password and Confirm Password Does not Match" });



        }
        else {
            this.setState({ error: "" });
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            else if (this.state.Id === 0) {
                this.createClick();

            }
            else {

                this.updateClick();
            }

            this.setState({ validated: true });

        }

    };



    editClick(emp) {
        debugger;
            
        this.setState({
            modalTitle: "Edit Property",
            Id: emp.id,
            Title: emp.title,
            PropertyType: emp.propertyType,
            Amount: emp.amount,
            Purpose: emp.purpose,
            Area: emp.area,
            City: emp.city,
            Bed: emp.bed,
            Bath: emp.bath,
            Country: emp.country,
            show: true,
            Picture: emp.picture,
            Address: emp.address,
            FromDate:emp.fromDate.split('T')[0],
            ToDate:emp.toDate.split('T')[0],

            

        });
    }

// getting the create estate
    createClick() {
        const st = {

            Title: this.state.Title,
            Id: this.state.Id,
            Purpose: this.state.Purpose,
            PropertyType: this.state.PropertyType,
            Country: this.state.Country,
            City: this.state.City,
            Address: this.state.Address,
            Bath: this.state.Bath,
            Bed: this.state.Bed,
            Amount: this.state.Amount,
            Picture: this.state.Picture,
            Area: this.state.Area,
            FromDate:this.state.FromDate,
            ToDate:this.state.ToDate,

            

        };
        console.log(st);
        fetch(variables.API_URL + 'estates/AddEstate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`

            },
            body: JSON.stringify(st)
        })

            .then(res => res.json())
            .then((result) => {
                if (!result.status) {
                    result.errors.map(x => {
                        toast.error(x);

                    });

                }
                else {
                    toast.success("Property Has been Added");

                    this.setState({ show: false });

                    this.refreshList();
                }

            }, (error) => {
                toast.error(error);

            })
    }

// getting the update estate 

    updateClick() {
        const jsonBody = {

            Title: this.state.Title,
            Id: this.state.Id,
            Purpose: this.state.Purpose,
            PropertyType: this.state.PropertyType,
            Country: this.state.Country,
            City: this.state.City,
            Address: this.state.Address,
            Bath: this.state.Bath,
            Bed: this.state.Bed,
            Amount: this.state.Amount,
            Picture: this.state.Picture,
            Area: this.state.Area,
            FromDate:this.state.FromDate,
            ToDate:this.state.ToDate,
            
        };
        fetch(variables.API_URL + 'estates/update', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
                
            },
            body: JSON.stringify(jsonBody)
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                if (!result.status) {

                    result.errors.map(x => {
                        toast.error(x);

                    });

                }
                else {
                    toast.success("Property Has been Updated");

                    this.setState({ show: false });

                    this.refreshList();
                }
            }, (error) => {

                toast.error(error);

            })
    }

// getting the delete estate

    deleteClick(id) {
        if (window.confirm('Are you sure?')) {
            fetch(variables.API_URL + 'estates/' + id, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                
                }
            })
                .then(res => res.json())
                .then((result) => {
                    toast.info("Property Has been Deleted");
                    this.refreshList();



                }, (error) => {

                    toast.error(error);

                })
        }
    }
// getting the Image upload estate

    imageUpload = (e) => {
        e.preventDefault();
        debugger;
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);

        fetch(variables.API_URL + 'estates/savefile', {
            method: 'POST',
            headers: {
               
                'Authorization': `Bearer ${getToken()}`
            
            },
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                this.setState({ Picture: data });
            }, (error) => {
                alert(error);
            })
    }
    
    onGlobalFilterChange = (e) => {
        this.setState({ globalFilterValue: e.target.value });
    };
    handleTitle = (e) => {
        this.setState({
            Title: e.target.value,
        });
    };
    handlePurpose = (e) => {
        this.setState({
            Purpose: e.target.value,
        });
    };

    handleFromDate = (e) => {
        this.setState({
            FromDate: e.target.value,
        });
    };
    handleToDate = (e) => {
        this.setState({
            ToDate: e.target.value,
        });
    };
    
    handleProptyType = (e) => {
        this.setState({
            PropertyType: e.target.value,
        });
    };
    handleAmount = (e) => {
        this.setState({
            Amount: e.target.value,
        });
    };
    handleBath = (e) => {
        this.setState({
            Bath: e.target.value,
        });
    };
    handleBed = (e) => {
        this.setState({
            Bed: e.target.value,
        });
    };
    handleArea = (e) => {
        this.setState({
            Area: e.target.value,
        });
    };
    handleChangeCountry = (e) => {
        this.setState({
            Country: e.target.value,
        });
    };
    handleAddress = (e) => {
        this.setState({
            Address: e.target.value,
        });
    };
    handlecity = (e) => {
        this.setState({
            City: e.target.value,
        });
    };

    actionTemplate = (rowData, column) => {
        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <button type="button"
                            className="btn btn-light mr-1"
                            onClick={() => this.editClick(rowData)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button"
                            className="btn btn-light mr-1"
                            onClick={() => this.deleteClick(rowData.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                            </svg>
                        </button>
                    </div>

                </div>




            </div>
        );
    };
    // getting the update status estate

    updateStatus(emp) {
        const st = {

            id: emp.id
        };
        fetch(variables.API_URL + 'estates/updateStatus', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
                'Authorization': `Bearer ${getToken()}`


            },
            body: JSON.stringify(st)
        })

            .then(res => res.json())
            .then((result) => {
                console.log(result);
                if (result.includes('Inactive')) {
                    toast.warning(result);

                }
                else {
                    toast.success(result);

                }
                this.refreshList();



            }, (error) => {
                alert('Failed');
            })
    }
    
    statusCheckbox = (rowData, column) => {
        return (
            <div>

                {isAdmin()?
                 <Form.Check
                 checked={rowData.isApproved}
                 onClick={() => this.updateStatus(rowData)}
                 feedbackType="invalid"
             />

                :
                <Form.Check
                checked={rowData.isApproved}
               
                feedbackType="invalid"
            />

                }
               


            </div>
        );
    };
    ImageRender = (rowData, column) => {
        return (
            
            <div>

            
<Image src={variables.PHOTO_URL+rowData.picture}   width={100} />


            </div>
        );
    };
    renderHeader = () => {
        const myStyles = {
            textAlign:'right',
          };
        return (
            <div className="row">
                <div className='col-md-6'>
                    <span className="">
                        <i className="pi pi-search" />
                        <input value={this.globalFilterValue} className='form-control' onChange={this.onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                </div>
                <div className='col-md-6 text-end'style={myStyles}>
                    <Button variant="primary" onClick={this.handleShow}>
                        Add New
                    </Button>

                </div>
            </div>
        );
    };

    render() {
        const {
            validated,
            setValidated,
            show,
            Estates,
            modalTitle,
            Id,
            Title, Purpose, PropertyType, City, Country, Address, Amount, Picture, Bath, Bed,Area,FromDate,ToDate,

            formValues, formErrors, isSubmit, filters, loading,globalFilterValue
        } = this.state;
        const filteredData = Estates.filter(item =>
            Object.values(item).some(value =>
              String(value).toLowerCase().includes(globalFilterValue.toLowerCase())
            )
          );
        return (
            <div>
                <ToastContainer />
                <div className='card'>
                    <div className='card-header'>
                        <h3>Estates</h3>
                    </div>
                    <div className='card-body'>
                        <div className="container">



                        </div>

                        <Modal show={this.state.show} onHide={this.handleClose} size="lg" backdrop="static"
                            keyboard={false} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{modalTitle}</Modal.Title>
                            </Modal.Header>

                            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                                <Modal.Body>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" id="fname" controlId="validationCustom01">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Title"

                                                value={Title}
                                                onChange={this.handleTitle}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Title
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="Role" controlId="validationCustom02">
                                            <Form.Label>Purpose</Form.Label>
                                            <Form.Select aria-label="Default select example" className='form-control'  required aria-describedby="Role"
                                                value={Purpose}
                                                onChange={this.handlePurpose}

                                            >
                                                <option value="Rent">Rent</option>
                                                <option value="Sell">Sell</option>

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Select Purpose Type
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="Role" controlId="validationCustom02">
                                            <Form.Label>Property Type</Form.Label>
                                            <Form.Select aria-label="Default select example" className='form-control' required aria-describedby="Role"
                                                value={PropertyType}
                                                onChange={this.handleProptyType}

                                            >
                                                <option value="Appartment">Appartment</option>
                                                <option value="Villa">Villa</option>
                                                <option value="Home">Home</option>
                                                <option value="Office">Office</option>
                                                <option value="Building">Building</option>
                                                <option value="Townhouse">Townhouse</option>
                                                <option value="Shop">Shop</option>
                                                <option value="Garage">Garage</option>

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Select Property Type
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="counrty" controlId="validationCustom02"

                                        >
                                            <Form.Label>Counrty</Form.Label>
                                            <Form.Select aria-label="Default select example" className='form-control'  required aria-describedby="Role"
                                                value={Country}
                                                onChange={this.handleChangeCountry}

                                            >
                                                <option value="Norway">Norway</option>


                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Select Country
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="City" controlId="validationCustom02"

                                        >
                                            <Form.Label>City</Form.Label>
                                            <Form.Select aria-label="Default select example" className='form-control'  required aria-describedby="Role"
                                                value={City}
                                                onChange={this.handlecity}

                                            >
                                                <option value="Ålesund">Ålesund</option>
                                                <option value="Ålgård">Ålgård</option>
                                                <option value="Åndalsnes">Åndalsnes</option>
                                                <option value="Ås">Ås</option>
                                                <option value="Åsgårdstrand">Åsgårdstrand</option>
                                                <option value="Alta">Alta</option>
                                                <option value="Arendal">Arendal</option>
                                                <option value="Asker">Asker</option>
                                                <option value="Askim">Askim</option>
                                                <option value="Askøy">Askøy</option>
                                                <option value="Bryne">Bryne</option>
                                                <option value="Brønnøysund">Brønnøysund</option>
                                                <option value="Drammen">Drammen</option>
                                                <option value="Drøbak">Drøbak</option>
                                                <option value="Elverum">Elverum</option>
                                                <option value="Førde">Førde</option>
                                                <option value="Flekkefjord">Flekkefjord</option>
                                                <option value="Florø">Florø</option>
                                                <option value="Gjøvik">Gjøvik</option>
                                                <option value="Grimstad">Grimstad</option>
                                                <option value="Halden">Halden</option>
                                                <option value="Hamar">Hamar</option>
                                                <option value="Hammerfest">Hammerfest</option>
                                                <option value="Harstad">Harstad</option>
                                                <option value="Haugesund">Haugesund</option>
                                                <option value="Holmestrand">Holmestrand</option>
                                                <option value="Horten">Horten</option>
                                                <option value="Hønefoss">Hønefoss</option>
                                                <option value="Jessheim">Jessheim</option>
                                                <option value="Jørpeland">Jørpeland</option>
                                                <option value="Kongsberg">Kongsberg</option>
                                                <option value="Kongsvinger">Kongsvinger</option>
                                                <option value="Kopervik">Kopervik</option>
                                                <option value="Kragerø">Kragerø</option>
                                                <option value="Kristiansand">Kristiansand</option>
                                                <option value="Kristiansund">Kristiansund</option>
                                                <option value="Langesund">Langesund</option>
                                                <option value="Larvik">Larvik</option>
                                                <option value="Leirvik">Leirvik</option>
                                                <option value="Leknes">Leknes</option>
                                                <option value="Levanger">Levanger</option>
                                                <option value="Lillehammer">Lillehammer</option>
                                                <option value="Mandal">Mandal</option>
                                                <option value="Mo i Rana">Mo i Rana</option>
                                                <option value="Molde">Molde</option>
                                                <option value="Mosjøen">Mosjøen</option>
                                                <option value="Moss">Moss</option>
                                                <option value="Mysen">Mysen</option>
                                                <option value="Narvik">Narvik</option>
                                                <option value="Notodden">Notodden</option>
                                                <option value="Nærbø">Nærbø</option>
                                                <option value="Namsos">Namsos</option>
                                                <option value="Narvik">Narvik</option>
                                                <option value="Odda">Odda</option>
                                                <option value="Orkanger">Orkanger</option>
                                                <option value="Oslo">Oslo</option>
                                                <option value="Porsgrunn">Porsgrunn</option>
                                                <option value="Råholt">Råholt</option>
                                                <option value="Raufoss">Raufoss</option>
                                                <option value="Rjukan">Rjukan</option>
                                                <option value="Sandefjord">Sandefjord</option>
                                                <option value="Sandnes">Sandnes</option>
                                                <option value="Sandvika">Sandvika</option>
                                                <option value="Sarpsborg">Sarpsborg</option>
                                                <option value="Skien">Skien</option>
                                                <option value="Ski">Ski</option>
                                                <option value="Stavanger">Stavanger</option>
                                                <option value="Stavern">Stavern</option>
                                                <option value="Steinkjer">Steinkjer</option>
                                                <option value="Svolvær">Svolvær</option>
                                                <option value="Tønsberg">Tønsberg</option>
                                                <option value="Tromsø">Tromsø</option>
                                                <option value="Trondheim">Trondheim</option>
                                                <option value="Vadsø">Vadsø</option>
                                                <option value="Vardø">Vardø</option>
                                                <option value="Verdalsøra">Verdalsøra</option>
                                                <option value="Volda">Volda</option>
                                                <option value="Voss">Voss</option>

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Select City
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                       
                                        <Form.Group as={Col} md="4" id="City" controlId="validationCustom02">
                                        <Form.Label>Area</Form.Label>
                                          
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                
                                                placeholder="Area"
                                                
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                value={Area}
                                                required
                                                onChange={this.handleArea}
                                            />
                                            <InputGroup.Text id="basic-addon2">.SqFt</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Area
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="City" controlId="validationCustom02">
                                        <Form.Label>Bed & Bath</Form.Label>
                                          
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                
                                                placeholder="Beds"
                                                
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                value={Bed}
                                                type="number"
                                                required
                                                onChange={this.handleBed}
                                            />
                                              <Form.Control
                                                
                                                placeholder="Bath"
                                                
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                value={Bath}
                                                type="number"
                                                required
                                                onChange={this.handleBath}
                                            />
                                            
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Bath & Bed
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="fname" controlId="validationCustom01">
                                            <Form.Label>Amount (NOK)</Form.Label>
                                            <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon2">NOK</InputGroup.Text>
                                         
                                            <Form.Control
                                                
                                                placeholder="Amount"
                                                
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                value={Amount}
                                                required
                                                onChange={this.handleAmount}
                                            />
                                            <InputGroup.Text id="basic-addon2">.00</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Amount (NOK)
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </InputGroup>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Title
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        {Id===0?
                                            <Form.Group as={Col} md="4" id="fname" controlId="validationCustom01">
                                            <Form.Label>Picture</Form.Label>
                                            <Form.Control
                                                
                                                placeholder="Picture"
                                                
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                
                                                required
                                                type='file'
                                                onChange={this.imageUpload}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please select Picture
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        
                                        :
                                        <Form.Group as={Col} md="4" id="fname" controlId="validationCustom01">
                                            <Form.Label>Picture</Form.Label>
                                            <Form.Control
                                                
                                                placeholder="Picture"
                                                
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                
                                                
                                                type='file'
                                                onChange={this.imageUpload}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please select Picture
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        
    }
                                       
                                        <Form.Group as={Col} md="6" id="FromDate" controlId="validationCustom02">
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control  aria-label="Default select example" type="date" required aria-describedby="address"
                                                value={FromDate}
                                                onChange={this.handleFromDate}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter From Date
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" id="FromDate" controlId="validationCustom02">
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control  aria-label="Default select example" type="date" required aria-describedby="address"
                                                value={ToDate}
                                                onChange={this.handleToDate}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter To Date
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" id="address" controlId="validationCustom02">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" aria-label="Default select example" required aria-describedby="address"
                                                value={Address}
                                                onChange={this.handleAddress}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Address
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>



                                    



                                     






                                    </Row>
                                    {/* <Row className="mb-3">
                                        <Form.Group as={Col} md="4" id="fname" controlId="validationCustom01">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="First name"

                                                value={firstName}
                                                onChange={this.handleChangeFname}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Last Name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="lname" controlId="validationCustom02">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Last name"

                                                aria-describedby="lname"
                                                value={lastName}
                                                onChange={this.handleChangeLname}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Last name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="email" controlId="validationCustom02">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={this.handleChangeEmailname}

                                                aria-describedby="email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter the Email.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                            <Form.Label>Username</Form.Label>
                                            <InputGroup hasValidation>

                                                <Form.Control
                                                    type="text"
                                                    placeholder="Username"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    value={username}
                                                    onChange={this.handleChangeUsername}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a username.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" id="Phone" controlId="validationCustom02">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Phone Number"

                                                aria-describedby="Phone"
                                                value={phoneNo}
                                                onChange={this.handleChangePhone}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Phone Number
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" id="Role" controlId="validationCustom02">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select aria-label="Default select example" required aria-describedby="Role"
                                                value={role}
                                                onChange={this.handleChangeRole}

                                            >
                                                <option value="Admin">Admin</option>
                                                <option value="Customer">Customer</option>

                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="address" controlId="validationCustom02">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" aria-label="Default select example" aria-describedby="address"
                                                value={address}
                                                onChange={this.handleChangeAddress}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" id="counrty" controlId="validationCustom02"

                                        >
                                            <Form.Label>Counrty</Form.Label>
                                            <Form.Select aria-label="Default select example" required aria-describedby="Role"
                                                value={country}
                                                onChange={this.handleChangeCountry}

                                            >
                                                <option value="Norway">Norway</option>


                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter First name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        {id === '' ?

                                            <Form.Group as={Col} md="4" id="Password" controlId="validationCustom02">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={this.handlePassword}

                                                    aria-describedby="Password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>


                                            : null


                                        }
                                        {id === '' ?


                                            <Form.Group as={Col} md="4" className="mt-1" id="ConfirmPassword" controlId="validationCustom02">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Confirm Password"

                                                    aria-describedby="ConfirmPassword"
                                                    value={confirmPassword}
                                                    onChange={this.handleConfirmPassword}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Confirm Password
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <p className='text-danger'>{error}</p>

                                            </Form.Group>

                                            : null


                                        }
                                        <Form.Group as={Col} md="12" className="mb-3">

                                            <Form.Check
                                                checked={approved}
                                                onChange={this.handleChangeApprove}
                                                label="Is Active"
                                                feedback="You must agree before submitting."
                                                feedbackType="invalid"
                                            />

                                        </Form.Group>




                                    </Row> */}

                                </Modal.Body>

                                <Modal.Footer>

                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button type="submit">Save</Button>



                                </Modal.Footer>
                            </Form>

                        </Modal>

                        <DataTable value={filteredData} showGridlines  paginator header={this.renderHeader} rows={10} size='small' fil rowsPerPageOptions={[5, 10, 25, 50]} stripedRows tableStyle={{ minWidth: '100rem' }} >
                            <Column field="id" header="Id" sortable  ></Column>
                            <Column field="title" header="Title" sortable ></Column>
                            <Column field="purpose" header="Purpose" sortable ></Column>
                            <Column field="propertyType" header="Property Type" sortable ></Column>

                            <Column field="country" header="Country" sortable ></Column>
                            <Column field="city" header="City" sortable ></Column>
                            <Column field="address" header="Address" sortable ></Column>
                            <Column field="area" header="Area" sortable ></Column>
                            <Column field="bed" header="Bed" sortable ></Column>
                            <Column field="bath" header="Bath" sortable ></Column>
                          
                            <Column field="amount" header="Amount" sortable ></Column>
                          
                            <Column field="isApproved" header="Status" body={this.statusCheckbox} sortable ></Column>
                            <Column field="pciture" header="Picture" body={this.ImageRender} sortable ></Column>
                           
                          <Column body={this.actionTemplate} style={{ textAlign: 'center' }} header="Action" />
                        </DataTable>


                    </div>
                </div>



            </div>
        )
    }
}