import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink, Link } from 'react-router-dom';
import { GetData, getToken, isLoggedIn } from './Accounts/Auth';
import variables from './Variables';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { ToastContainer, toast } from 'react-toastify';
class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            name:GetData("fullName"),
            phone:GetData("phone"),
            email:GetData("email"),
            receivedBy:'',
            propertyId:'',
            customerResponse:'',
            isLoading: true,
            show: false,
            validated: false,
            propertyType:'',
            keyword:'',
            city:'',
            showSearch:false,
            searched:[]
        };
    }
     getDateFromDateAndTimeString(dateTimeString) {
        const dateObject = new Date(dateTimeString);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    
   // handleShow = () => this.setState({ show: true });
    handleClose=()=>this.setState({ show: false });
    
    handleShow=(item)=>{
        this.setState({ show: true,propertyId:item.id,receivedBy:item.addedBy });

    }
    handleName = (e) => {
        this.setState({
            name: e.target.value,
        });
    };
    handlePhone = (e) => {
        this.setState({
            phone: e.target.value,
        });
    };
    handleptype = (e) => {
        this.setState({
            propertyType: e.target.value,
        });
    };
    handleCity = (e) => {
        this.setState({
            city: e.target.value,
        });
    };
    handleKeyword = (e) => {
        this.setState({
            keyword: e.target.value,
        });
    };
    handleEmail = (e) => {
        this.setState({
            email: e.target.value,
        });
    };
    handleResponse = (e) => {
        this.setState({
            customerResponse: e.target.value,
        });
    };
    handleSubmit = (event) => {
        debugger;
        event.preventDefault();
        const form = event.currentTarget;
        if (this.state.password !== this.state.confirmPassword) {
            toast.error("Password and Confirm Password Does not Match");
            this.setState({ error: "Password and Confirm Password Does not Match" });



        }
        else {
            this.setState({ error: "" });
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            else {
                
                this.createClick();



            }
           
            this.setState({ validated: true });

        }

    };
    createClick() {
        const st = {

            PropertyId: this.state.propertyId,
            Name: this.state.name,
            Email: this.state.email,
            PhoneNo: this.state.phone,
            CustomerResponse: this.state.customerResponse,
            ResponseSendBy: GetData("Name"),
            ResponseReceivedBy: this.state.receivedBy,
            Title:"test",
            Image:"TEST",

            

        };
        console.log(st);
        fetch(variables.API_URL + 'contacts/addContact', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`

            },
            body: JSON.stringify(st)
        })

            .then(res => res.json())
            .then((result) => {
                if (!result.status) {
                    result.errors.map(x => {
                        toast.error(x);

                    });

                }
                else {
                    console.log(result);
                    toast.success("Response Is Saved Successfully");
                    this.setState({ customerResponse: '' });

                    this.setState({ show: false });

                
                }

            }, (error) => {
                toast.error(error);

            })
    }
    Search=()=>{
        const filtersDi={
            ptype:this.state.propertyType,
            city:this.state.city,
            keyword:this.state.keyword,
            
        }
        console.log(filtersDi);
        debugger;
        fetch(variables.API_URL + 'estates/searching', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                

            },
            body: JSON.stringify(filtersDi)
        })
        .then(res => res.json())
        .then((result) => {
           console.log(result);
           this.setState({ searched: result,showSearch:true });
           
        }, (error) => {
            console.log(error);
            toast.error(error);

        })
    }

    componentDidMount() {

        AOS.init();

        fetch(variables.API_URL + 'estates/getall')
            .then(response => response.json())
            .then(data => this.setState({ items: data }))
            .finally(() => {

                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 1000);

            });



    }
    render() {
         const { items,showSearch,searched, isLoading, show,validated,name,email,phone,customerResponse,keyword,propertyType,city } = this.state;
        return (
            <div>
                <ToastContainer/>



<Modal show={this.state.show} onHide={this.handleClose}  backdrop="static"
                            keyboard={false} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Contact</Modal.Title>
                            </Modal.Header>
                            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                           
                            <Modal.Body>
                            <Row className="mb-3">
                                        <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Title"

                                                value={name}
                                                onChange={this.handleName}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email"

                                                value={email}
                                                onChange={this.handleEmail}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Email
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Phone Number"

                                                value={phone}
                                                onChange={this.handlePhone}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Phone Number
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="address" controlId="validationCustom02">
                                            <Form.Label>Customer Response</Form.Label>
                                            <Form.Control as="textarea" aria-label="Default select example" required aria-describedby="address"
                                                value={customerResponse}
                                                onChange={this.handleResponse}

                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Response
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                      

                                    



                                     






                                    </Row>

                            </Modal.Body> <Modal.Footer>

                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button type="submit">Save</Button>



                                </Modal.Footer>
                                </Form>


</Modal>
                <section className="banner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="banner-content" >
                                    <h1>Find Your Best Dream House for <span>Rental, Buy & Sell...</span></h1>
                                    <p>Properties for buy / rent in in your location. We have more than 3000+ listings for you to choose</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-search" >
                                    <div className="banner-tab">
                                        <ul className="nav nav-tabs" id="bannerTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link active" id="buy-property" data-bs-toggle="tab" href="#buy_property" role="tab" aria-controls="buy_property" aria-selected="true">
                                                    <img src="assets/img/icons/buy-icon.svg" alt="icon"></img> Search a Property
                                                </a>
                                            </li>
                                            {/*<li className="nav-item" role="presentation">*/}
                                            {/*    <a className="nav-link" id="rent-property" data-bs-toggle="tab" href="#rent_property" role="tab" aria-controls="rent_property" aria-selected="false">*/}
                                            {/*        <img src="assets/img/icons/rent-icon.svg" alt="icon"></img> Rent a Property*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="bannerTabContent">
                                        <div className="tab-pane fade show active" id="buy_property" role="tabpanel" aria-labelledby="buy-property">
                                            <div className="banner-tab-property">
                                                <form onSubmit={this.Search}>
                                                    <div className='row'>
                                                        <div className='col-md-3'> <select className="select form-control" value={propertyType} onChange={this.handleptype}>
                                                                <option value="">Select a Property Type...</option>
                                                                <option value="Appartment">Appartment</option>
                                                                <option value="Villa">Villa</option>
                                                                <option value="Home">Home</option>
                                                                <option value="Office">Office</option>
                                                                <option value="Building">Building</option>
                                                                <option value="Townhouse">Townhouse</option>
                                                                <option value="Shop">Shop</option>
                                                                <option value="Garage">Garage</option>
                                                            </select></div>
                                                        <div className='col-md-5'>
                                                        <input type="text" className="form-control" placeholder="Search Keyword" value={keyword} onChange={this.handleKeyword}></input>
                                                   
                                                        </div>
                                                        <div className='col-md-2'>
                                                        <select className="select form-control" value={city} onChange={this.handleCity}>
                                                                <option value="">Select City</option>
                                                                <option value="Ålesund">Ålesund</option>
                                                                <option value="Ålgård">Ålgård</option>
                                                                <option value="Åndalsnes">Åndalsnes</option>
                                                                <option value="Ås">Ås</option>
                                                                <option value="Åsgårdstrand">Åsgårdstrand</option>
                                                                <option value="Alta">Alta</option>
                                                                <option value="Arendal">Arendal</option>
                                                                <option value="Asker">Asker</option>
                                                                <option value="Askim">Askim</option>
                                                                <option value="Askøy">Askøy</option>
                                                                <option value="Bryne">Bryne</option>
                                                                <option value="Brønnøysund">Brønnøysund</option>
                                                                <option value="Drammen">Drammen</option>
                                                                <option value="Drøbak">Drøbak</option>
                                                                <option value="Elverum">Elverum</option>
                                                                <option value="Førde">Førde</option>
                                                                <option value="Flekkefjord">Flekkefjord</option>
                                                                <option value="Florø">Florø</option>
                                                                <option value="Gjøvik">Gjøvik</option>
                                                                <option value="Grimstad">Grimstad</option>
                                                                <option value="Halden">Halden</option>
                                                                <option value="Hamar">Hamar</option>
                                                                <option value="Hammerfest">Hammerfest</option>
                                                                <option value="Harstad">Harstad</option>
                                                                <option value="Haugesund">Haugesund</option>
                                                                <option value="Holmestrand">Holmestrand</option>
                                                                <option value="Horten">Horten</option>
                                                                <option value="Hønefoss">Hønefoss</option>
                                                                <option value="Jessheim">Jessheim</option>
                                                                <option value="Jørpeland">Jørpeland</option>
                                                                <option value="Kongsberg">Kongsberg</option>
                                                                <option value="Kongsvinger">Kongsvinger</option>
                                                                <option value="Kopervik">Kopervik</option>
                                                                <option value="Kragerø">Kragerø</option>
                                                                <option value="Kristiansand">Kristiansand</option>
                                                                <option value="Kristiansund">Kristiansund</option>
                                                                <option value="Langesund">Langesund</option>
                                                                <option value="Larvik">Larvik</option>
                                                                <option value="Leirvik">Leirvik</option>
                                                                <option value="Leknes">Leknes</option>
                                                                <option value="Levanger">Levanger</option>
                                                                <option value="Lillehammer">Lillehammer</option>
                                                                <option value="Mandal">Mandal</option>
                                                                <option value="Mo i Rana">Mo i Rana</option>
                                                                <option value="Molde">Molde</option>
                                                                <option value="Mosjøen">Mosjøen</option>
                                                                <option value="Moss">Moss</option>
                                                                <option value="Mysen">Mysen</option>
                                                                <option value="Narvik">Narvik</option>
                                                                <option value="Notodden">Notodden</option>
                                                                <option value="Nærbø">Nærbø</option>
                                                                <option value="Namsos">Namsos</option>
                                                                <option value="Narvik">Narvik</option>
                                                                <option value="Odda">Odda</option>
                                                                <option value="Orkanger">Orkanger</option>
                                                                <option value="Oslo">Oslo</option>
                                                                <option value="Porsgrunn">Porsgrunn</option>
                                                                <option value="Råholt">Råholt</option>
                                                                <option value="Raufoss">Raufoss</option>
                                                                <option value="Rjukan">Rjukan</option>
                                                                <option value="Sandefjord">Sandefjord</option>
                                                                <option value="Sandnes">Sandnes</option>
                                                                <option value="Sandvika">Sandvika</option>
                                                                <option value="Sarpsborg">Sarpsborg</option>
                                                                <option value="Skien">Skien</option>
                                                                <option value="Ski">Ski</option>
                                                                <option value="Stavanger">Stavanger</option>
                                                                <option value="Stavern">Stavern</option>
                                                                <option value="Steinkjer">Steinkjer</option>
                                                                <option value="Svolvær">Svolvær</option>
                                                                <option value="Tønsberg">Tønsberg</option>
                                                                <option value="Tromsø">Tromsø</option>
                                                                <option value="Trondheim">Trondheim</option>
                                                                <option value="Vadsø">Vadsø</option>
                                                                <option value="Vardø">Vardø</option>
                                                                <option value="Verdalsøra">Verdalsøra</option>
                                                                <option value="Volda">Volda</option>
                                                                <option value="Voss">Voss</option>
                                                            </select>

                                                        </div>
                                                        <div className='col-md-2'>
                                                        <a onClick={this.Search} className="btn-primary"><span><i className='feather-search'></i></span></a>
                                                     
                                                        </div>
                                                  
                                                    </div>
                                                    <div className="banner-property-info">

                                                        <div className="banner-property-grid">
                                                           
                                                        </div>
                                                        <div className="banner-property-grid">
                                                        </div>
                                                        <div className="banner-property-grid">
                                                        
                                                        </div>
                                                        {/* <div className="banner-property-grid">
                                                            <input type="number" className="form-control" placeholder="Min Price" />
                                                        </div>
                                                        <div className="banner-property-grid">
                                                            <input type="number" className="form-control" placeholder="Max Price" />
                                                        </div> */}
                                                        <div className="banner-property-grid">
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {showSearch?
                
                <section>
                     <div class="listing-section">
                      <div class="container">

                  
                    <div class="showing-result-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3">
                                <div class="result-show">
                                    <h5>Showing result <span>{searched.searched}</span> of <span>{searched.totall}</span></h5>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="sort-result">
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div class=" for-rent p-0">
                        <div class="row">

                        
                            
                            {searched.estates.map(item => (
                            
                                <div class="col-lg-4 col-md-6">
                                    <div className="product-custom">
                                        <div className="profile-widget">
                                            <div className="doc-img">
                                                <a  class="property-img">
                                                    <img class="img-fluid" alt="Property Image" src="assets/img/product/product-3.jpg" />
                                                </a>


                                                <div className="product-amount">
                                                    <span>${item.amount}</span>
                                                </div>
                                                <div className="feature-rating">
                                                    <div>
                                                        <div className="featured">
                                                            <span>{item.purpose}</span>
                                                        </div>
                                                    </div>
                                                    {/*<a href="javascript:void(0)">*/}
                                                    {/*    <div className="favourite">*/}
                                                    {/*        <span><i className="fa-regular fa-heart"></i></span>*/}
                                                    {/*    </div>*/}
                                                    {/*</a>*/}
                                                </div>
                                                <div className="user-avatar">
                                                            <div>
                                                                {isLoggedIn()?
                                                                 <a className="btn-primary" onClick={() => this.handleShow(item)} >Contact</a>

                                                                :
                                                                <NavLink to="/login"  className="btn-primary">Login to Contact</NavLink>

                                                                }
                                                               
                                                            </div>

                                                        </div>
                                            </div>
                                            <div className="pro-content">
                                                <div className="rating">
                                                    {/*<span className="rating-count">*/}
                                                    {/*    <i className="fa-solid fa-star checked"></i>*/}
                                                    {/*    <i className="fa-solid fa-star checked"></i>*/}
                                                    {/*    <i className="fa-solid fa-star checked"></i>*/}
                                                    {/*    <i className="fa-solid fa-star checked"></i>*/}
                                                    {/*    <i className="fa-solid fa-star"></i>*/}
                                                    {/*</span>*/}
                                                    {/*  <p className="rating-review"><span>4.0</span>(28 Reviews)</p>*/}
                                                </div>
                                                <h3 className="title">
                                                    <a >{item.title}</a>
                                                </h3>
                                                <p><i className="feather-map-pin"></i>{item.address} ,{item.city} ,{item.country}  </p>
                                                <ul className="d-flex details">
                                                    <li>
                                                        <img src="assets/img/icons/bed-icon.svg" alt="bed-icon" />
                                                        {item.bed} Beds
                                                    </li>
                                                    <li>
                                                        <img src="assets/img/icons/bath-icon.svg" alt="bath-icon" />
                                                        {item.bath}  Bath
                                                    </li>
                                                    <li>
                                                        <img src="assets/img/icons/building-icon.svg" alt="building-icon" />
                                                        {item.area} Sqft
                                                    </li>
                                                </ul>

                                                <ul className="property-category d-flex justify-content-between">
                                                    <li>
                                                        <span className="list">Listed on : </span>
                                                        <span className="date">{this.getDateFromDateAndTimeString(item.addedAt)}</span>
                                                    </li>
                                                    <li>
                                                        <span className="category list">Category : </span>
                                                        <span className="category-value date">{item.propertyType}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            
                             ))}
                         
                        </div>
                    </div>
                </div>
            </div>
                </section>
                
                :null}
                <section className="howit-work">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2>How It Works</h2>
                            <div className="sec-line">
                                <span className="sec-line1"></span>
                                <span className="sec-line2"></span>
                            </div>
                            <p>Follow these 3 steps to book your place</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="howit-work-card"  data-aos-duration="1200" data-aos-delay="100">
                                    <div className="work-card-icon">
                                        <span>
                                            <img src="/assets/img/icons/work-icon-1.svg" alt="icon" />
                                        </span>
                                    </div>
                                    <h4>01. Search for Location</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio…</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="howit-work-card"  data-aos-duration="1200" data-aos-delay="200">
                                    <div className="work-card-icon">
                                        <span className="bg-red">
                                            <img src="assets/img/icons/work-icon-2.svg" alt="icon" />
                                        </span>
                                    </div>
                                    <h4>02. Select Property Type</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio…</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="howit-work-card"  data-aos-duration="1200" data-aos-delay="300">
                                    <div className="work-card-icon">
                                        <span className="bg-green">
                                            <img src="assets/img/icons/work-icon-3.svg" alt="icon" />
                                        </span>
                                    </div>
                                    <h4>03. Book Your Property</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio…</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="property-type-sec">
                    <div className="section-shape-imgs">
                        <img src="./assets/img/shapes/property-sec-bg-1.png" className="rectangle-left" alt="icon" />
                        <img src="./assets/img/shapes/property-sec-bg-2.png" className="rectangle-right" alt="icon" />
                        <img src="./assets/img/icons/red-circle.svg" className="bg-09" alt="Image" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="section-heading"  >
                                    <h2>Explore by Property Type</h2>
                                    <div className="sec-line">
                                        <span className="sec-line1"></span>
                                        <span className="sec-line2"></span>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed </p>
                                </div>
                                <div className="owl-navigation">
                                    <div className="owl-nav mynav1 nav-control"></div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <OwlCarousel items={4}
                                    className="owl-theme property-type-slider owl-carousel"
                                    loop
                                    nav
                                    margin={8} >

                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="assets/img/icons/property-icon-1.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Houses</h4>
                                            <p>30 Properties</p>
                                        </div>
                                    </div>
                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="assets/img/icons/property-icon-2.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Offices</h4>
                                            <p>25 Properties</p>
                                        </div>
                                    </div>
                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="assets/img/icons/property-icon-3.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Villas</h4>
                                            <p>40 Properties</p>
                                        </div>
                                    </div>
                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="assets/img/icons/property-icon-4.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Apartment</h4>
                                            <p>35 Properties</p>
                                        </div>
                                    </div>
                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="assets/img/icons/property-icon-3.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Villas</h4>
                                            <p>40 Properties</p>
                                        </div>
                                    </div>
                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="assets/img/icons/property-icon-1.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Houses</h4>
                                            <p>30 Properties</p>
                                        </div>
                                    </div>
                                    <div className="property-card"  >
                                        <div className="property-img">
                                            <img src="./assets/img/icons/property-icon-4.svg" alt="icon" />
                                        </div>
                                        <div className="property-name">
                                            <h4>Apartment</h4>
                                            <p>35 Properties</p>
                                        </div>
                                    </div>
                                </OwlCarousel>


                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="feature-property-sec">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2>Featured Properties for Sales</h2>
                            <div className="sec-line">
                                <span className="sec-line1"></span>
                                <span className="sec-line2"></span>
                            </div>
                            <p>Hand-Picked selection of quality places</p>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <OwlCarousel items={3}
                                    className="owl-theme feature-slider"

                                    nav
                                    margin={8} >
                                    {items.map(item => (

                                        <div className="slider-col">
                                            <div className="product-custom"  >
                                                <div className="profile-widget">
                                                    <div className="doc-img">
                                                        <NavLink tag={Link} className="property-img" >

                                                            <img className="img-fluid" alt="Property Image" src={variables.PHOTO_URL+item.picture} />
                                                        </NavLink>


                                                        <div className="product-amount">
                                                            <span>${item.amount}</span>
                                                        </div>
                                                        <div className="feature-rating">
                                                            <div>
                                                                <div className="featured">
                                                                    <span>{item.purpose}</span>
                                                                </div>
                                                            </div>
                                                            {/* <a href="javascript:void(0)">
                                                                <div className="favourite">
                                                                    <span><i className="fa-regular fa-heart"></i></span>
                                                                </div>
                                                            </a> */}
                                                        </div>
                                                        <div className="user-avatar">
                                                            <div>
                                                                {isLoggedIn()?
                                                                 <a className="btn-primary" onClick={() => this.handleShow(item)} >Contact</a>

                                                                :
                                                                <NavLink to="/login"  className="btn-primary">Login to Contact</NavLink>

                                                                }
                                                               
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="pro-content">
                                                        <div className="rating">
                                                            {/* <span className="rating-count">
                                                                <i className="fa-solid fa-star checked"></i>
                                                                <i className="fa-solid fa-star checked"></i>
                                                                <i className="fa-solid fa-star checked"></i>
                                                                <i className="fa-solid fa-star checked"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                            <p className="rating-review"><span>4.0</span>(28 Reviews)</p> */}
                                                        </div>
                                                        <h3 className="title">


                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <a >{item.title}</a>
                                                                </div>

                                                            </div>

                                                        </h3>
                                                        <p><i className="feather-map-pin"></i>{item.address} , {item.city}, {item.country} </p>
                                                        <ul className="d-flex details">
                                                            <li>
                                                                <img src="assets/img/icons/bed-icon.svg" alt="bed-icon" />
                                                                {item.bed} Beds
                                                            </li>
                                                            <li>
                                                                <img src="assets/img/icons/bath-icon.svg" alt="bath-icon" />
                                                                {item.bath}  Bath
                                                            </li>
                                                            <li>
                                                                <img src="assets/img/icons/building-icon.svg" alt="building-icon" />
                                                                {item.area} Sqft
                                                            </li>
                                                        </ul>

                                                        <ul className="property-category d-flex justify-content-between">
                                                            <li>
                                                                <span className="list">Listed on : </span>
                                                                <span className="date">{this.getDateFromDateAndTimeString(item.addedAt)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="category list">Category : </span>
                                                                <span className="category-value date">{item.propertyType}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    ))}


                                </OwlCarousel>

                                <div className="view-property-btn d-flex justify-content-center"  >

                                    {/* <NavLink tag={Link} className="btn-primary" to="/properties">View All Properties

                                    </NavLink> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-imgs">
                        <img src="assets/img/bg/price-bg.png" className="bg-01" alt="icon"></img>
                        <img src="assets/img/icons/blue-circle.svg" className="bg-02" alt="icon" />
                        <img src="assets/img/icons/red-circle.svg" className="bg-03" alt="icon" />
                    </div>
                </section>




                <section className="counter-sec">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                                <div className="counter-box flex-fill"  data-aos-duration="2000">
                                    <div className="counter-icon">
                                        <img src="assets/img/icons/counter-icon-1.svg" alt="icon" />
                                    </div>
                                    <div className="counter-value">
                                        <h3 className="dash-count"><span className="counter-up">50</span>K</h3>
                                        <h5>Listings Added </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                                <div className="counter-box flex-fill"  data-aos-duration="2000">
                                    <div className="counter-icon">
                                        <img src="assets/img/icons/counter-icon-2.svg" alt="icon" />
                                    </div>
                                    <div className="counter-value">
                                        <h3 className="dash-count"><span className="counter-up">3000</span>+</h3>
                                        <h5>Agents Listed </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                                <div className="counter-box flex-fill"  data-aos-duration="2000">
                                    <div className="counter-icon">
                                        <img src="assets/img/icons/counter-icon-3.svg" alt="icon" />
                                    </div>
                                    <div className="counter-value">
                                        <h3 className="dash-count"><span className="counter-up">2000</span>+</h3>
                                        <h5>Sales Completed </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 d-flex">
                                <div className="counter-box flex-fill"  data-aos-duration="2000">
                                    <div className="counter-icon">
                                        <img src="assets/img/icons/counter-icon-4.svg" alt="icon" />
                                    </div>
                                    <div className="counter-value">
                                        <h3 className="dash-count"><span className="counter-up">5000</span>+</h3>
                                        <h5>Users</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="faq-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="faq-img">
                                    <img src="assets/img/faq-img.jpg" alt="icon"></img>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="section-heading"  data-aos-duration="2000">
                                    <h2>Frequently Asked Questions</h2>
                                    <div className="sec-line">
                                        <span className="sec-line1"></span>
                                        <span className="sec-line2"></span>
                                    </div>
                                    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                                </div>
                                <div className="faq-card"  data-aos-duration="2000">
                                    <h4 className="faq-title">
                                        <a className="collapsed" data-bs-toggle="collapse" href="#faqone" aria-expanded="false">1. What are the costs to buy a house?</a>
                                    </h4>
                                    <div id="faqone" className="card-collapse collapse">
                                        <div className="faq-info">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="faq-card"  data-aos-duration="2000">
                                    <h4 className="faq-title">
                                        <a className="collapsed" data-bs-toggle="collapse" href="#faqtwo" aria-expanded="false">2. What are the steps to sell a house?</a>
                                    </h4>
                                    <div id="faqtwo" className="card-collapse collapse">
                                        <div className="faq-info">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="faq-card"  data-aos-duration="2000">
                                    <h4 className="faq-title">
                                        <a className="" data-bs-toggle="collapse" href="#faqthree" aria-expanded="false">3. Do you have loan consultants?</a>
                                    </h4>
                                    <div id="faqthree" className="card-collapse collapse show">
                                        <div className="faq-info">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="faq-card"  data-aos-duration="2000">
                                    <h4 className="faq-title">
                                        <a className="collapsed" data-bs-toggle="collapse" href="#faqfour" aria-expanded="false">4. When will the project be completed?</a>
                                    </h4>
                                    <div id="faqfour" className="card-collapse collapse">
                                        <div className="faq-info">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="faq-card"  data-aos-duration="2000">
                                    <h4 className="faq-title">
                                        <a className="collapsed" data-bs-toggle="collapse" href="#faqfive" aria-expanded="false">5.What are the steps to rent a house?</a>
                                    </h4>
                                    <div id="faqfive" className="card-collapse collapse">
                                        <div className="faq-info">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonial-sec">
                    <div className="container">
                        <div className="section-heading">
                            <h2>Testimonials</h2>
                            <div className="sec-line">
                                <span className="sec-line1"></span>
                                <span className="sec-line2"></span>
                            </div>
                            <p>What our happy client says</p>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <OwlCarousel items={3}
                                    className="owl-theme testimonial-slider"
                                    loop
                                    nav
                                    margin={8} >
                                    <div className="testimonial-card"  data-aos-duration="2000">
                                        <div className="user-icon">
                                            <a href="javascript:void(0);"><img src="assets/img/profiles/avatar-01.jpg" alt="User" /></a>
                                        </div>
                                        <p>Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...</p>
                                        <h4><a href="javascript:void(0);">Horace Cole</a></h4>
                                        <div className="rating">
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                        </div>
                                    </div>
                                    <div className="testimonial-card"  data-aos-duration="2000">
                                        <div className="user-icon">
                                            <a href="javascript:void(0);"><img src="assets/img/profiles/avatar-02.jpg" alt="User" /></a>
                                        </div>
                                        <p>Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...</p>
                                        <h4><a href="javascript:void(0);">Karen Maria</a></h4>
                                        <div className="rating">
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                        </div>
                                    </div>
                                    <div className="testimonial-card"  data-aos-duration="2000">
                                        <div className="user-icon">
                                            <a href="javascript:void(0);"><img src="assets/img/profiles/avatar-03.jpg" alt="User" /></a>
                                        </div>
                                        <p>Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...</p>
                                        <h4><a href="javascript:void(0);">Jack Nitzsche</a></h4>
                                        <div className="rating">
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                        </div>
                                    </div>
                                    <div className="testimonial-card"  data-aos-duration="2000">
                                        <div className="user-icon">
                                            <a href="javascript:void(0);"><img src="assets/img/profiles/avatar-04.jpg" alt="User" /></a>
                                        </div>
                                        <p>Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...</p>
                                        <h4><a href="javascript:void(0);">Sofia Retz</a></h4>
                                        <div className="rating">
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                        </div>
                                    </div>
                                    <div className="testimonial-card"  data-aos-duration="2000">
                                        <div className="user-icon">
                                            <a href="javascript:void(0);"><img src="assets/img/profiles/avatar-05.jpg" alt="User" /></a>
                                        </div>
                                        <p>Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...Omnis velit quia. Perspiciatis et cupiditate. Voluptatum beatae asperiores dolor magnam fuga. Sed fuga est harum quo nesciunt sint. Optio veniam...</p>
                                        <h4><a href="javascript:void(0);">John Doe</a></h4>
                                        <div className="rating">
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                            <span><i className="fa-solid fa-star"></i></span>
                                        </div>
                                    </div>
                                </OwlCarousel>
                                <div className="testimonial-slider owl-carousel">

                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>

        );
    }
}

export default Home;
