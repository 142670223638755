import React, { Component } from 'react';
import variables from '../Variables';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { ToastContainer, toast } from 'react-toastify';
import Image from 'react-bootstrap/Image';
import 'react-toastify/dist/ReactToastify.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Dialog } from 'primereact/dialog';
import { isLoggedIn, GetData, getToken, isAdmin } from '../Accounts/Auth';
import { useNavigate } from 'react-router-dom';

export class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {

            contacts: [],
            show: false,
            modalTitle: "Contact",
            name:'',
            phone:'',
            email:'',
            receivedBy:'',
            propertyId:'',
            customerResponse:'',
            globalFilterValue:''


        }
    }



    refreshList() {

        fetch(variables.API_URL + 'contacts/GetContacts', {
            headers: {
                'Authorization': `Bearer ${getToken()}`

            }})

            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({ contacts: data });
            });

    }
    handleClose = () => this.setState({
        modalTitle: "Contact",
        show: false,
        name:'',
        phone:'',
        email:'',
        receivedBy:'',
        propertyId:'',
        customerResponse:'',

            


    });
    handleShow = () => this.setState({ show: true });


    componentDidMount() {
        // const navigate = useNavigate();


        this.refreshList();
    }
    
    handleSubmit = (event) => {
        debugger;
        event.preventDefault();
        const form = event.currentTarget;
        if (this.state.password !== this.state.confirmPassword) {
            toast.error("Password and Confirm Password Does not Match");
            this.setState({ error: "Password and Confirm Password Does not Match" });



        }
        else {
            this.setState({ error: "" });
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            else if (this.state.Id === 0) {
                this.createClick();

            }
            else {

                this.updateClick();
            }

            this.setState({ validated: true });

        }

    };

    ImageRender = (rowData, column) => {
        return (
            
            <div>

            
<Image src={variables.PHOTO_URL+rowData.image}   width={100} />


            </div>
        );
    };

    editClick(emp) {
        debugger;
            
        this.setState({
            modalTitle: "Contact",
            propertyId:emp.propertyId,
            name: emp.name,
            email: emp.email,
            phone: emp.phoneNo,
            customerResponse: emp.customerResponse,
            show:true,
            

        });
    }

  

    actionTemplate = (rowData, column) => {
        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <button type="button"
                            className="btn btn-primary mr-1"
                            onClick={() => this.editClick(rowData)}>
                                Response
                        </button>
                    </div>
                   

                </div>




            </div>
        );
    };
    
    onGlobalFilterChange = (e) => {
        this.setState({ globalFilterValue: e.target.value });
    };
    statusCheckbox = (rowData, column) => {
        return (
            <div>

                {isAdmin()?
                 <Form.Check
                 checked={rowData.isApproved}
                 onClick={() => this.updateStatus(rowData)}
                 feedbackType="invalid"
             />

                :
                <Form.Check
                checked={rowData.isApproved}
               
                feedbackType="invalid"
            />

                }
               


            </div>
        );
    };
   
    renderHeader = () => {
        const myStyles = {
            textAlign:'right',
          };
        return (
            <div className="row">
                <div className='col-md-6'>
                    <span className="">
                        <i className="pi pi-search" />
                        <input value={this.globalFilterValue} className='form-control' onChange={this.onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                </div>
                <div className='col-md-6 text-end'style={myStyles}>
                  
                </div>
            </div>
        );
    };

    render() {
        const {
            validated,
            setValidated,
            show,
            name,
            phone,
            email,
            receivedBy,
            propertyId,
            customerResponse
            ,contacts,modalTitle,globalFilterValue
        } = this.state;
        const filteredData = contacts.filter(item =>
            Object.values(item).some(value =>
              String(value).toLowerCase().includes(globalFilterValue.toLowerCase())
            )
          );
        return (
            <div>
                <ToastContainer />
                <div className='card'>
                    <div className='card-header'>
                        <h3>Response</h3>
                    </div>
                    <div className='card-body'>
                        <div className="container">



                        </div>

                        <Modal show={this.state.show} onHide={this.handleClose} size="lg" backdrop="static"
                            keyboard={false} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{modalTitle}</Modal.Title>
                            </Modal.Header>

                            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                                <Modal.Body>
                                    
                                <Row className="mb-3">
                                        <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                readOnly
                                                placeholder="Title"

                                                value={name}

                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Name
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email"
                                                readOnly
                                             
                                                value={email}
                                            
                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Email
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="fname" controlId="validationCustom01">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Phone Number"
                                                readOnly
                                             
                                                value={phone}
                                              
                                                aria-describedby="fname"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Phone Number
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" id="address" controlId="validationCustom02">
                                            <Form.Label>Customer Response</Form.Label>
                                            <Form.Control as="textarea" aria-label="Default select example" required aria-describedby="address"
                                                value={customerResponse}
                                                readOnly
                                             
                                            >


                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Response
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                      

                                    



                                     






                                    </Row>

                                  

                                </Modal.Body>

                                <Modal.Footer>

                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                   


                                </Modal.Footer>
                            </Form>

                        </Modal>

                        <DataTable value={filteredData} showGridlines paginator header={this.renderHeader} rows={10} size='small' fil rowsPerPageOptions={[5, 10, 25, 50]} stripedRows tableStyle={{  }} >
                            <Column field="propertyId" header="Id"  ></Column>
                            <Column field="title" header="Title" sortable ></Column>
                            <Column field="image" header="Picture" body={this.ImageRender} sortable ></Column>
                           
                          <Column body={this.actionTemplate} style={{ textAlign: 'center' }} header="Response" />
                        </DataTable>


                    </div>
                </div>



            </div>
        )
    }
}