import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink,Link, Routes, Outlet, useNavigate ,redirect  } from 'react-router-dom';

import { getToken,GetData ,isLoggedIn,logout, isAdmin} from '../Accounts/Auth';

import { ToastContainer, toast } from 'react-toastify';

const Header = () => {
    // const navigate=useNavigate();
    // const logoutHnd=()=>{
        
    //     logout();      
    //     toast.success("Logout Successfuly");
    //     navigate("/");
    //     redirect("/login");
    
    // }
    

    return (

        
        <header className="header header-fix">
        <ToastContainer/>
        <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
                <a id="mobile_btn" href="javascript:void(0);">
                    <span className="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a>
                <NavLink to="/" className="navbar-brand logo">
                {/* <img src="assets/img/logo.svg" className="img-fluid" alt="Logo"></img> */}
               
                    <img src="assets/lg.png"  className="img-fluid" alt="Logo"></img>
                </NavLink>
            </div>
            <div className="main-menu-wrapper">
                <div className="menu-header">
                    <NavLink  to="/" className="menu-logo">
                    {/* <img src="assets/img/logo.svg" className="img-fluid" alt="Logo"></img> */}
               
                        <img src="assets/lg.png"  className="img-fluid" alt="Logo"></img>
                    </NavLink>
    
                    <a id="menu_close" className="menu-close" href="javascript:void(0);">
                        <i className="fas fa-times"></i>
                    </a>
                </div>
                <ul className="main-nav">
                    <li className="active">
                        
                        <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
    
                    </li>
                    {isLoggedIn()?
                      <li>
                        
                      <NavLink tag={Link} className="text-dark" to="/profile">Profile</NavLink>
  
                  </li>

                : 
                null}
                  
                    {isAdmin()?
                      <li>
                        
                      <NavLink tag={Link} className="text-dark" to="/user">User</NavLink>
  
                  </li>:null

}
    
                 
                    
                    <li className="login-link">
                    <NavLink to="/signup">Sign Up</NavLink>
                       
                       </li>
                    <li className="login-link">
                        
                        <NavLink to="/login">Sign In</NavLink>
                        </li>

                </ul>
            </div>
            <ul className="nav header-navbar-rht">
                {/* <li className="new-property-btn">
                    <a href="add-new-property.html">
                        <i className="bx bxs-plus-circle"></i> Add New Property
                    </a>

                </li> */}
               
                {isLoggedIn()?null:
                 <li>
                         <NavLink to="/signup"  className="btn btn-primary"><i className="feather-user-plus" ></i> Sign Up</NavLink>
            
                         </li>
                }

                {isLoggedIn()?
                <p>Hello !  {GetData("fullName")}   &nbsp; &nbsp; 
                <NavLink to="/logout"   className="btn btn-damger"><i className="feather-unlock"></i> Sign out</NavLink>
                 

                 </p>
               :
                <li>
                <NavLink to="/login"  className="btn sign-btn"><i className="feather-unlock"></i> Sign In</NavLink>
                     
                    
                </li>


                }
                
            </ul>
        </nav>
    
    </header>
    
    );
}

export default Header;
