import React from 'react';
import { Outlet,useNavigate,useHistory,useLocation ,NavLink  } from 'react-router-dom';

import { login, SaveData ,isLoggedIn,logout ,GetData, isAdmin} from '../Accounts/Auth';
const Sidebar = () => {
    return (
        <div>
            
            <div className="card">
                <div className="card-body">
                    <div class="left-sidebar-widget">
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item"><NavLink className="nav-link @ManageNavPages.IndexNavClass(ViewContext)" id="profile" to="/profile">Profile</NavLink></li>
                         
                            <li class="nav-item"><NavLink className="nav-link @ManageNavPages.EmailNavClass(ViewContext)" id="email" to="/changePassword">Change Password</NavLink></li>
                         
                            {isAdmin()?
                                <li class="nav-item"><NavLink className="nav-link @ManageNavPages.ChangePasswordNavClass(ViewContext)" to="/user">Users</NavLink></li>

                            :null}
                            
                            <li class="nav-item"><NavLink className="nav-link @ManageNavPages.TwoFactorAuthenticationNavClass(ViewContext)" id="two-factor" to="/estate">Properties</NavLink></li>
                            <li class="nav-item"><NavLink className="nav-link @ManageNavPages.PersonalDataNavClass(ViewContext)" id="personal-data" to="/response">Response</NavLink></li>
                        </ul>



                    </div>

                </div>
            </div>
            
        </div>
    );
}

export default Sidebar;
