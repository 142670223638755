import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet,useNavigate } from 'react-router-dom';
import { login, SaveData ,isLoggedIn,logout} from '../Accounts/Auth';
import { ToastContainer, toast } from 'react-toastify';

const Frontend = () => {
    const navigate=useNavigate();

   
    return (
        <div>
            
            <Header/>
               <Outlet/>
            <Footer/>
        </div>
    );
}

export default Frontend;
