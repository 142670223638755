
export const login = (token) => {
    sessionStorage.setItem('token', token);
  };
  
  export const SaveData = (key,value) => {
    sessionStorage.setItem(key,value);
  };
  export const GetData = (key) => {
    return sessionStorage.getItem(key);
    
  };
  
  
  // Logout function to remove the token from sessionStorage
  export const logout = () => {
    sessionStorage.removeItem('token');
  };
  export const isAdmin = () => {
    return sessionStorage.getItem('role')==="Admin";
    
  };
  
  // Function to check if the user is logged in
 
  export const getToken = () => {
    return sessionStorage.getItem('token');

  };

  export const isLoggedIn = () => {
    return sessionStorage.getItem('token') !== null;
  };
