import React  from 'react';
import Sidebar from './Sidebar';
import { Outlet,useNavigate,useHistory,useLocation   } from 'react-router-dom';

import Footer from '../FrontEnd/Footer';
import Header from '../FrontEnd/Header';
import { login, SaveData ,isLoggedIn,logout ,GetData} from '../Accounts/Auth';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';

const Backend = () => {
    const navigate=useNavigate();
    const location = useLocation();
        
    useEffect(() => {
        
        console.log(location.pathname);

        // Check for the existence of the token on component load
        if (!isLoggedIn()) {
        
            
          // If the token exists, navigate to the desired page
          navigate('/login');

        }
        if(location.pathname==="/user"){
            if(GetData("role")!=="Admin"){
                          
                navigate("/error");

            }
        }
      }, [navigate]); 

    

 
    return (
        <div>
            
            <div className='row'>
                <div className='col-md-12'>
               <Header/>
                </div>
                <div className='col-md-3'>
                <Sidebar></Sidebar>

                </div>
                <div className='col-md-9'>
                <Outlet></Outlet>
                </div>
                <div className='col-md-12'>
                  <Footer/>
                </div>
                
                
            </div>
        
        </div>
    );
}

export default Backend;
